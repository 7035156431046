import { createBrowserHistory } from "history";
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import BlogDetailPage from "./components/Blogs/BlogsDetailPage";
import Cart from "./components/Cart/Cart";
import ContactUs from "./components/ContactUS/ContactUs";
import AddressBookForm from "./components/CustomerDashboard/AddressBookForm";
import AllOrders from "./components/CustomerDashboard/AllOrders";
import CustomerProfilePage from "./components/CustomerDashboard/CustomerProfilePage";
import Footer from "./components/Footer/Footer";
import GoToTop from "./components/GoToTop/GoToTop";
import ViewInvoiceForm from "./components/Invoice/ViewInvoiceForm";
import Loader from "./components/LoaderGif/Loader";
import FixedNavbar from "./components/MainNavabar/FixedNavbar";
import ForgotPassword from "./components/Pages/ForgetPassword";
import OurStory from "./components/Pages/OurStory";
import PageNotFound from "./components/Pages/PageNotFound";
import PaymentFail from "./components/Pages/PaymentFail";
import PaymentSuccess from "./components/Pages/PaymentSuccess";
import StaticPages from "./components/Pages/StaticPages";
import ProductDetail from "./components/ProductDetails/ProductDetail";
import Productlisting from "./components/ProductListing/ProductListing";
import WriteAReview from "./components/ProductReviews/WriteReview";
import ProtectedRoutes from "./components/RouthAuth/ProtectedRoutes";
import TopBar from "./components/TopBar/TopBar";
import Provider from "./context/store";
import GetApiCall from "./helpers/GetApi";
import "./index.css";
import Faq from "./pages/FAQ/Faq";
import Home from "./pages/Home";
import OrderSuccess from "./components/Pages/OrderSuccess";

const history = createBrowserHistory();
function App() {
  const [staticPages, setStaticPages] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate loading state with setTimeout
    setTimeout(() => setLoading(false), 500); // Example: loading for 2 seconds
  }, []);
  useEffect(() => {
    GetApiCall.getRequest("GetStaticPagesList").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);

  return (
    <Provider>
      <Suspense fallback={<div></div>}>
        <Router history={history}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <TopBar />
              <FixedNavbar />
              <GoToTop />
              <Switch>
                {staticPages?.map((dat, index) => {
                  return (
                    <Route
                      path={[dat.fld_route]}
                      key={index}
                      component={StaticPages}></Route>
                  );
                })}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/writereview/:variantid`}
                  component={WriteAReview}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/forgotpassword`}
                  component={ForgotPassword}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/`}
                  component={Home}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/cart`}
                  component={Cart}></Route>
                <ProtectedRoutes
                  exact
                  path={`${process.env.PUBLIC_URL}/paymentsuccess/:txnid`}
                  component={PaymentSuccess}
                  isAuth={
                    localStorage.getItem("CustomerData") == null ? false : true
                  }
                />
                <ProtectedRoutes
                  exact
                  path={`${process.env.PUBLIC_URL}/ordersuccess/:txnid`}
                  component={OrderSuccess}
                  isAuth={
                    localStorage.getItem("CustomerData") == null ? false : true
                  }
                />
                <ProtectedRoutes
                  exact
                  path={`${process.env.PUBLIC_URL}/paymentfailed`}
                  component={PaymentFail}
                  isAuth={
                    localStorage.getItem("CustomerData") == null ? false : true
                  }
                />
                <ProtectedRoutes
                  exact
                  path={`${process.env.PUBLIC_URL}/customerprofile`}
                  component={CustomerProfilePage}
                  isAuth={
                    localStorage.getItem("CustomerData") == null ? false : true
                  }
                />
                <Route
                  exact
                  path={"/blog-detail/:blogTitle"}
                  component={BlogDetailPage}
                />
                <Route
                  exact
                  path={"/view-invoice-form/:id/:orderid"}
                  component={ViewInvoiceForm}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/productdetail/:productname/:variantid`}
                  component={ProductDetail}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/addressbookform`}
                  component={AddressBookForm}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/contact-us`}
                  component={ContactUs}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/faqs`}
                  component={Faq}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/our-story`}
                  component={OurStory}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/ct/:categoryname/:verticalid/:categoryid`}
                  component={Productlisting}></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/ct/:categoryname/:verticalid/:categoryid/:subcatid`}
                  component={Productlisting}></Route>
                <ProtectedRoutes
                  exact
                  path={"/all-orders"}
                  component={AllOrders}
                  isAuth={
                    localStorage.getItem("CustomerData") == null ? false : true
                  }
                />
                <Route component={PageNotFound} />
              </Switch>
            </>
          )}
          <Footer />
        </Router>
      </Suspense>
    </Provider>
  );
}

export default App;
