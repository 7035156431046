import { Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import GSName from "../../assets/Images/home-category/gs-name.jpg";
import GSImg from "../../assets/Images/home-category/gs.jpg";
import GTName from "../../assets/Images/home-category/gt-name.jpg";
import GTImg from "../../assets/Images/home-category/gt.jpg";
import MEName from "../../assets/Images/home-category/me-name.jpg";
import MEImg from "../../assets/Images/home-category/me.jpg";
import WFName from "../../assets/Images/home-category/wf-name.jpg";
import WFImg from "../../assets/Images/home-category/wf.jpg";
import { store } from "../../context/store";
import GetApiCall from "../../helpers/GetApi";
import "./HomeMainBanner.css";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
export default function HomeMainBanner() {
  const [banner, setBanner] = useState([]);
  const { vertical } = useContext(store);
  useEffect(() => {
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Main"
          );
          // console.log(filterBanner[0].image)
          setBanner(filterBanner);
        }
      })
    );
  }, []);
  const productsliderdemo = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    autoplaySpeed: 4000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="px-lg-3 home-main-banner">
      <div className="container-fluid pt-2">
        <div className="row pt-1">
          <div className="col-lg-7 arrow-section pe-lg-0">
            {banner.length > 0 ? (
              <Slider {...productsliderdemo}>
                {banner.map((img) => {
                  return (
                    <Link className="main-banner websitebanner">
                      <img src={img.image} className="img-fluid" />
                    </Link>
                  );
                })}
              </Slider>
            ) : (
              <div className="h-100 pb-2">
                <Skeleton.Image className="w-100 h-100 slider-skel-img"></Skeleton.Image>
              </div>
            )}
          </div>
          <div className="col-lg-5 pe-lg-4">
            {banner.length > 0 ? (
              <div className="row">
                <Link
                  to="/ct/adults/3/6"
                  className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <div className="flip-container">
                    <div className="flipper">
                      <div className="front">
                        <img src={MEImg} className="img-fluid" />
                      </div>
                      <div className="back">
                        <img src={MEName} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/ct/adults/2/4"
                  className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <div className="flip-container">
                    <div className="flipper">
                      <div className="front">
                        <img src={GTImg} className="img-fluid" />
                      </div>
                      <div className="back">
                        <img src={GTName} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/ct/adults/4/8"
                  className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <div className="flip-container">
                    <div className="flipper">
                      <div className="front">
                        <img src={GSImg} className="img-fluid" />
                      </div>
                      <div className="back">
                        <img src={GSName} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </Link>
                <Link
                  to="/ct/adults/1/2"
                  className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <div className="flip-container">
                    <div className="flipper">
                      <div className="front">
                        <img src={WFImg} className="img-fluid" />
                      </div>
                      <div className="back">
                        <img src={WFName} className="img-fluid" />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div className="row h-100">
                <div className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <Skeleton.Image className="w-100 h-100"></Skeleton.Image>
                </div>
                <div className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <Skeleton.Image className="w-100 h-100"></Skeleton.Image>
                </div>
                <div className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <Skeleton.Image className="w-100 h-100"></Skeleton.Image>
                </div>
                <div className="col-6 pe-lg-0 mb-lg-2 mb-1 pb-1 main-banner-right">
                  <Skeleton.Image className="w-100 h-100"></Skeleton.Image>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
