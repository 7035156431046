import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
export default function RelatedProducts({ itemdata }) {
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [variantList, setVariantList] = useState(null);
  const [mrp, setMrp] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [youSave, setYouSave] = useState(null);
  const [savePer, setSavePer] = useState(null);
  const [stock, setStock] = useState(null);
  const [weight, setWeight] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [itemType, setItemType] = useState("");

  useEffect(() => {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });
    try {
      setItemType(itemdata.item_type);
      setItemName(itemdata.fld_variantname);
      setVariantList(itemdata.variant_list);
      setMrp(itemdata.fld_mrp);
      setDiscountPrice(itemdata.fld_website_discount_price);
      setSavePer(itemdata.you_save_per);
      setYouSave(itemdata.you_save_amount);
      setStock(itemdata.fld_stock_in_hand);
      setVariantId(itemdata.fld_variantid);
      setVariantName(itemdata.fld_variantname);
      setWeight(itemdata.weight);
      setCategoryName(itemdata.fld_categoryname);
      setVariantImage(itemdata.VariantImage);
      setProductId(itemdata.fld_productid);
    } catch {
      console.log("No related products to show");
    }
  }, []);

  return (
    <div className="px-2">
      <div className="item">
        <div className="product-box">
          <div className="p-images blog-images position-relative">
            <Link
              to={`${process.env.PUBLIC_URL
                }/productdetail/${itemdata.fld_variantname.replace(
                  /\s/g,
                  "-"
                ).replace('/', '')}/${variantId}`}>
              <img
                src={variantImage}
                alt={itemdata.fld_variantname.replace(/\s/g, "-")} className="img-fluid mb-2"></img>
              {itemdata.fld_discount_percent > 0 ? (
                <span className="offer-label">
                  {parseInt(itemdata.fld_discount_percent)}% Discount
                </span>
              ) : (
                ""
              )}
              <span
                className={itemType == null ? "d-none" : "veg-nonveg-icon"}>
                <img
                  // src={imageSrc}
                  alt={itemType}
                />
              </span>
            </Link>
          </div>

          <div className="content-box position-relative">
            <span style={{ color: "#00000075" }}>{categoryName}</span>

            <p className="product-title2">
              <p>
                <Link
                  to={`${process.env.PUBLIC_URL
                    }/productdetail/${itemdata.fld_variantname?.replace(
                      /\s/g,
                      "-"
                    ).replace('/', '')}/${variantId}`}>
                  {itemdata.fld_itemname}
                </Link>
              </p>
              <Link
                to={`${process.env.PUBLIC_URL
                  }/productdetail/${itemdata.fld_variantname.replace(
                    /\s/g,
                    "-"
                  ).replace('/', '')}/${variantId}`}>
                {variantName?.split("(")[0]}
                {variantName?.split("(")[1] ? "(" : ""}
                {variantName?.split("(")[1]}
              </Link>
            </p>
            <p className="price">
              <i className="fas fa-rupee-sign"></i> ₹{" "}
              {parseFloat(discountPrice).toFixed(2)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
