import { useEffect, useState } from "react";
import "./ProductCard.css";
export default function ProductCard(props) {
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [productId, setProductId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [variantList, setVariantList] = useState(null);
  const [MRP, setMRP] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [variantImagesList, setVariantImagesList] = useState([]);
  const [savePer, setSavePer] = useState(null);
  const [stock, setStock] = useState(null);
  const [weight, setWeight] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [itemType, setItemType] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  useEffect(() => {
    try {
      setItemType(props.itemdata.item_type);
      setItemName(props.itemdata.fld_variantname);
      setVariantList(props.itemdata.variant_list);
      setMRP(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[2]
      );
      setDiscountPrice(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[3]
      );
      setSavePer(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[4]
      );

      setStock(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[6]
      );
      setVariantId(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[1]
      );
      setVariantName(
        props.itemdata.variant_list.split("#")[0].split("$")[1].split(",")[0]
      );
      setCategoryName(props.itemdata.fld_categoryname);

      setVariantImage(
        props.itemdata.variant_list
          .split("#")[0]
          .split("$")[1]
          .split(",")[7]
          .split("~")[0]
      );
      setProductId(props.itemdata.fld_productid);
    } catch {
      try {
        setItemName(props.itemdata.fld_variantname);
        setVariantList(props.itemdata.variant_list);
        setMRP(props.itemdata.variant_list.split("$")[1].split(",")[2]);
        setDiscountPrice(
          props.itemdata.variant_list.split("$")[1].split(",")[3]
        );
        setSavePer(props.itemdata.variant_list.split("$")[1].split(",")[4]);
        setStock(props.itemdata.variant_list.split("$")[1].split(",")[6]);
        setVariantId(props.itemdata.variant_list.split("$")[1].split(",")[1]);
        setVariantName(props.itemdata.variant_list.split("$")[1].split(",")[0]);
        setWeight(props.itemdata.variant_list.split("$")[1].split(",")[8]);
        setCategoryName(props.itemdata.fld_categoryname);

        setVariantImage(
          props.itemdata.variant_list.split("$")[1].split(",")[7].split("~")[0]
        );
        setProductId(props.itemdata.fld_productid);
      } catch { }
    }
  }, []);
  return (
    <>
      <>
        <div className="item mb-4 mb-lg-0">
          <div className="product-box">
            <div className="p-images blog-images position-relative">
              <a
                href={`${process.env.PUBLIC_URL
                  }/productdetail/${props.itemdata.fld_variantname?.replace(
                    /\s/g,
                    "-"
                  ).replace('/', '')}/${variantId}`}>
                <img
                  src={variantImage}
                  alt={props.itemdata.fld_variantname?.replace(/\s/g, "-")}
                  className="img-fluid"></img>
                {props.itemdata.fld_discount_percent > 0 ? (
                  <span className="offer-label">
                    <p>
                      {parseInt(props.itemdata.fld_discount_percent)}% OFF
                    </p>
                  </span>
                ) : (
                  ""
                )}
              </a>
            </div>

            <div className="content-box position-relative text-start mt-2 mt-lg-3">
              <span style={{ color: "#00000075" }}>{categoryName}</span>

              <p className="product-title2">
                <p>
                  <a
                    href={`${process.env.PUBLIC_URL
                      }/productdetail/${props.itemdata.fld_variantname?.replace(
                        /\s/g,
                        "-"
                      ).replace('/', '')}/${variantId}`}>
                    {props.itemdata.fld_itemname}
                  </a>
                </p>
                {/* <a
                  href={`${process.env.PUBLIC_URL
                    }/productdetail/${props.itemdata.fld_variantname?.replace(
                      /\s/g,
                      "-"
                    ).replace('/', '')}/${variantId}`}>
                  {variantName?.split("(")[0]}
                  {variantName?.split("(")[1] ? "(" : ""}
                  {variantName?.split("(")[1]}
                </a> */}
              </p>
              <p className="price">
                <i className="fas fa-rupee-sign"></i> ₹{" "}
                {parseFloat(discountPrice).toFixed(0)}
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
