import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import PostApiCall from "../../helpers/Api";
// import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from "antd";
import { useParams } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import "./ProductListing.css";
// import sortIcon from "./assets/img/sorticon.svg";

import GetSeoData from "../../helpers/GetSeoData";

export default function Productlisting() {
  const params = useParams();
  const [Items, setItems] = useState([]);
  const [productFilter, setProductFilter] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  useEffect(() => {
    getProductCategories();
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params.categoryid, params.subcatid]);

  const getProductCategories = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: `where fld_vertical_id = ${params.verticalid}`,
      },
      "GetProductCategories"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setProductFilter(obj1.data);
          // on sale navitem is static
          setSelectedCategoryName(obj1.data[0]?.vertical_name != "On Sale" ? obj1.data[0]?.vertical_name : "");
          getItems();
        }
      })
    );
  };
  const getItems = () => {
    PostApiCall.postRequest(
      {
        categoryid: parseInt(params.categoryid),
        subcategoryid: parseInt(params.subcatid),
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // setItems(obj1.data.filter((data) => data.fld_status == "Active"));
          const datasort = obj1.data.filter((data) => data.fld_status == "Active");
          setItems([...datasort].sort((a, b) => b.fld_productid - a.fld_productid))

          setDataLoading(false);
        }
      })
    );
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <GetSeoData type="Category" id={params.categoryid} />
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>
          {capitalizeFirstLetter(params.categoryname) +
            " " +
            selectedCategoryName}
        </title>
        <meta name="description" content="Helmet application" />
      </Helmet> */}
      {/* <section className="inner-banner">
				<div className="main-banner mobileinner-banner">
					<img
						// src={isMobile ? CartBannerMobile : CartBanner}
						alt="inner-banner"
						className="img-responsive"
					/>
				</div>
			</section> */}
      <div className="">
        <>
          <section className="page-padding products-inner px-lg-3">
            <div className="container-fluid pt-4">
              <div className="row vertical-product-height mb-5">
                {/* <div className="col-12 mb-md-4">
                  <div className="product-filter-list w-100">
                    {productFilter?.map((product, i) => (
                      <div className="category-wrapper-header">
                        <Link
                          to={`${process.env.PUBLIC_URL
                            }/ct/${(product.fld_categoryname
                              ? product.fld_categoryname.toLowerCase()
                              : ""
                            ).replace(/\s/g, "-")}/${product?.fld_vertical_id}/${product?.fld_categoryid
                            }`}
                          onClick={() => {
                            setDataLoading(true);
                            setSelectedCategoryName(product.fld_categoryname);
                          }}
                          className="dropdown-item productFilters justify-content-center">
                          <div className="d-flex align-items-center w-100">
                            <img
                              src={product.fld_image}
                              className="category-image-header"
                            />
                            <b className="pl-2">{product.fld_name}</b>
                          </div>
                          {params.categoryname ==
                            product.fld_categoryname.toLowerCase() ? (
                            <span>
                              <TiTick />
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      </div>
                    ))}
                  </div>
                </div> */}
                <div className=" col-12">
                  <div className="section-title text-center mt-lg-3 mb-md-5 position-relative">
                    <h4 className="section-title-category-section productlisting-heading">
                      {capitalizeFirstLetter(params.categoryname) +
                        " " +
                        selectedCategoryName}
                    </h4>
                    {/* <Space wrap className="d-flex justify-content-end product-listing-sort">
											<Select
												defaultValue="Sort by"
												style={{
													width: 170,
												}}
												onChange={(value) => {
													let sortedItems = [...Items];

													if (value === "Price, High to Low") {
														sortedItems.sort(
															(a, b) =>
																b.fld_website_discount_price -
																a.fld_website_discount_price
														);
													} else if (value === "Price, Low to High") {
														sortedItems.sort(
															(a, b) =>
																a.fld_website_discount_price -
																b.fld_website_discount_price
														);
													} else if (value === "Alphabetically, A to Z") {
														sortedItems.sort((a, b) =>
															a.fld_variantname.localeCompare(b.fld_variantname)
														);
													} else if (value === "Alphabetically, Z to A") {
														sortedItems.sort((a, b) =>
															b.fld_variantname.localeCompare(a.fld_variantname)
														);
													}
													setItems(sortedItems)
												}}
												options={[
													{
														value: "Price, High to Low",
														label: "Price, High to Low",
													},
													{
														value: "Price, Low to High",
														label: "Price, Low to High",
													},
													{
														value: "Alphabetically, A to Z",
														label: "Alphabetically, A to Z",
													},
													{
														value: "Alphabetically, Z to A",
														label: "Alphabetically, Z to A",
													},
												]}
											/>
											// <img src={sortIcon} className="product-listing-sort-icon" />
										</Space> */}
                  </div>
                  <div className="row vertical-section product-card-section product-listing-box mt-4">
                    {Items.length > 0
                      ? Items
                        //.filter((dat) => {
                        //	if (dat.fld_website_discount_price > 1) {
                        //		return dat
                        //	}
                        //})
                        .map((val) => {
                          return (
                            <div
                              key={val.fld_variantid}
                              className="product-listing-card col-6 col-lg-3 mb-2 mb-mb-3 px-2 px-md-auto">
                              <ProductCard itemdata={val} />
                            </div>
                          );
                        })
                      : dataLoading === false && (
                        <div className="d-flex justify-content-center align-items-center w-100 listingpage-height">
                          Items you are searching for are not avaiable
                        </div>
                      )}
                    {dataLoading === true && (
                      <div className="d-flex w-100">
                        <div className="item col-lg-3">
                          <div className="wall-frame-right-card3 px-2">
                            <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                            <div>
                              <Skeleton.Button />
                            </div>
                            <div className="mt-2">
                              <Skeleton.Input />
                            </div>
                          </div>
                        </div>
                        <div className="item col-lg-3">
                          <div className="wall-frame-right-card3 px-2">
                            <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                            <div>
                              <Skeleton.Button />
                            </div>
                            <div className="mt-2">
                              <Skeleton.Input />
                            </div>
                          </div>
                        </div>
                        <div className="item col-lg-3 d-none d-lg-block">
                          <div className="wall-frame-right-card3 px-2">
                            <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                            <div>
                              <Skeleton.Button />
                            </div>
                            <div className="mt-2">
                              <Skeleton.Input />
                            </div>
                          </div>
                        </div>
                        <div className="item col-lg-3 d-none d-lg-block">
                          <div className="wall-frame-right-card3 px-2">
                            <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                            <div>
                              <Skeleton.Button />
                            </div>
                            <div className="mt-2">
                              <Skeleton.Input />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </div>
    </>
  );
}
