import { Skeleton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import bannerImg from "../../../assets/Images/website_Banner_gifting ideas2.jpg";
import { store } from "../../../context/store";
import PostApiCall from "../../../helpers/Api";
import "./CategoryWithBanner.css";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}
export default function CategoryWithBanner(props) {
  const [products, setProducts] = useState([]);
  const { banner } = useContext(store);
  useEffect(() => {
    PostApiCall.postRequest(
      { sectionname: "Category2" },
      "GetWebsiteHomePageItems"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        setProducts(obj.data);
      })
    );
  }, []);
  const productsliderdemo = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <>
      {console.log(banner)}
      <section className="pt-lg-5 pb-4">
        <div className="d-flex d-lg-none heading-style mb-0">
          <div className="d-flex justify-content-between align-items-end mobile-padding px-lg-4 pb-4">
            <div>
              {/* for mobile */}
              <h2>Gifting Ideas</h2>
              <h3 className="mb-0">
                Gift your loved ones with our personalized envelopes. Each
                envelope adds a special touch to your presents. Make your gifts
                extra meaningful with our unique designs!
              </h3>
            </div>
            {/* <Link to="/" className="theme-btn w-50 text-center">
              View All
            </Link> */}
          </div>
        </div>
        <div className="container-fluid category-banner-section">
          <div className="row">
            <div className="col-md-6 position-relative">
              <img
                src={
                  bannerImg
                  // props.categoryimage
                }
                // className="category-left-img"
                className="img-fluid"
              />
              {/* <div className="category-left-content"> */}
              {/* <div className="section-title mb-4">
                  <h4 className="section-title-category-section">
                     {this.props.Categoryname}
                    Gifting Ideas
                  </h4>
                </div> */}
              {/* <p>
                  Unveil Delight: Discover Adorable Envelopes for Every Occasion.
                  Unveil Delight: Discover Adorable Envelopes for Every Occasion.
                </p> */}
              {/* </div> */}
            </div>
            <div className="col-md-6 category-banner-slider">
              <div className="w-100 flex-column justify-content-center d-none d-lg-flex">
                <h2>Gifting Ideas</h2>
                <p>
                  Gift your loved ones with our personalized envelopes. Each
                  envelope adds a special touch to your presents. Make your
                  gifts extra meaningful with our unique designs!
                </p>
                {/* <div className="mt-3">
                  <Link to="/" className="theme-btn">
                    View All
                  </Link>
                </div> */}
              </div>
              <div className="arrow-section product-card-section mt-2 pt-1 mt-lg-4">
                <Slider {...productsliderdemo}>
                  {banner.length > 0 ? (
                    banner
                      ?.filter((item) => item.location === "gifttingIdeas")
                      .map((img, index) => {
                        return (
                          <Link to={`${img.slug}`} key={index}>
                            <img
                              src={img.image.split("#")[0]}
                              className="img-fluid"
                            />
                          </Link>
                        );
                      })
                  ) : (
                    <div className="main-slider-box">
                      <div className="item">
                        <div className="product-box">
                          <Skeleton.Image className="p-images blog-images" />
                          <Skeleton.Button className="content-box position-relative" />
                        </div>
                      </div>
                      <div className="item">
                        <div className="product-box">
                          <Skeleton.Image className="p-images blog-images" />
                          <Skeleton.Button className="content-box position-relative" />
                        </div>
                      </div>
                      <div className="item d-none d-lg-block">
                        <div className="product-box">
                          <Skeleton.Image className="p-images blog-images" />
                          <Skeleton.Button className="content-box position-relative" />
                        </div>
                      </div>
                    </div>
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
