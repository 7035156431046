import React from "react";
import "./TestimonialSection.css"
import { MdCurrencyRupee } from "react-icons/md";
import { FaShoppingBag } from "react-icons/fa";
import { Link } from "react-router-dom";
import Testi from "../../assets/Images/static-image/testi.jpg"
import { FaStar } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Autoplay } from "swiper";
import SwiperCore, { Pagination } from 'swiper';
import { Skeleton } from "antd";
SwiperCore.use([Pagination]);
export default function TestimonialSection({ websiteTestimonailData, isLoading }) {
    console.log(isLoading)
    return (
        <div div className="bg-light testimonial-section py-4 mb-5 mt-lg-4" >
            {/* <section className=" mb-5 px-lg-4 mt-lg-4"> */}
            {/* <div className="container-fluid bg-light py-5 mb-5 mt-lg-4">
                <div className="row"> */}
            <div className="section-head text-center t-head ">
                <h4><span>Client  </span>Reviews </h4>
                <p className="mb-3">Customer Experiences You Can Trust
                </p>
            </div>
            {/* {console.log(websiteTestimonailData)} */}

            {/* swiper */}
            {isLoading === true ?
                <div className="row p-lg-0 p-3">
                    <div className="col-lg-3">
                        <div className="">
                            <Skeleton.Image className="img-fluid skelton-img-testimonial" />
                        </div>
                        <div className="testimonial-content pt-3">
                            {/* <h3 className="mb-0">Item Ordered:</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="review w-100" />
                            <Skeleton.Input className="name w-100 text-end" />
                        </div>
                    </div>
                    <div className="col-lg-3 d-none d-lg-block">
                        <div className="">
                            <Skeleton.Image className="img-fluid skelton-img-testimonial" />
                        </div>
                        <div className="testimonial-content pt-3">
                            {/* <h3 className="mb-0">Item Ordered:</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="review w-100" />
                            <Skeleton.Input className="name w-100 text-end" />
                        </div>
                    </div>
                    <div className="col-lg-3 d-none d-lg-block">
                        <div className="">
                            <Skeleton.Image className="img-fluid skelton-img-testimonial" />
                        </div>
                        <div className="testimonial-content pt-3">
                            {/* <h3 className="mb-0">Item Ordered:</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="review w-100" />
                            <Skeleton.Input className="name w-100 text-end" />
                        </div>
                    </div>
                    <div className="col-lg-3 d-none d-lg-block">
                        <div className="">
                            <Skeleton.Image className="img-fluid skelton-img-testimonial" />
                        </div>
                        <div className="testimonial-content pt-3">
                            {/* <h3 className="mb-0">Item Ordered:</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="mb-1 w-100" />
                            <Skeleton.Input className="review w-100" />
                            <Skeleton.Input className="name w-100 text-end" />
                        </div>
                    </div>
                </div> :
                <Swiper
                    spaceBetween={40}
                    // slidesPerView={2}
                    pagination={{ clickable: true }}
                    loop={websiteTestimonailData.length > 3 ? true : false}
                    autoplay={true}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper testi-swiper container-fluid pt-2 pb-5"

                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 80,
                        },
                    }}

                >

                    {websiteTestimonailData.length && (
                        websiteTestimonailData.map((testimonial) => {
                            return (
                                <SwiperSlide>
                                    <div className="testiomonial-image">
                                        <img src={testimonial.fld_image} className="img-fluid" />
                                    </div>
                                    <div className="testimonial-content pt-3">
                                        <h3 className="mb-0">Item Ordered:</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                                        <h3 className="mb-0">Client Review:</h3>
                                        <p className="review">{testimonial.fld_feedback}</p>
                                        <p className="name"> <b>- {testimonial.fld_name}</b></p>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    )
                    }
                </Swiper>
            }







            {/* </div>
            </div> */}


            {/* </section > */}
        </div >
    )

}




